.paragraph {
  padding-top: 16px;
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: 992px) {
    padding-top: 2.5%;
    font-size: calc(14px + 4 * (100vw / 1280));
    line-height: calc(24px + 5.5 * (100vw / 1280));
  }
}

.strong {
  font-weight: 600;
}
