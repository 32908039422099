@import "./assets/variables/color.scss";

.wrapperContainer {
  @media screen and (min-width: 992px) {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-bg;
    gap: 16px;
  }
}

// poster

.posterContainer {
  position: fixed;
  padding: 0 10px;
  // height: 100vw;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  @media (max-height: 375px) {
    display: none;
  }

  @media (min-width: 475px) and (max-width: 992px) {
    height: 70vw;
    width: 70vw;
    padding: 0;
  }

  @media (min-width: 992px) and (max-width: 1370px) {
    width: 55vw;
    height: 55vw;

    padding: 0;
    position: static;
    border-radius: 24px;
    transform: none;
  }

  @media screen and (min-width: 1370px) {
    padding: 0;
    position: static;
    width: 45vw;
    height: 45vw;
    border-radius: 24px;
    transform: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 24px;

  @media (min-width: 475px) and (max-width: 992px) {
    width: 100%;
  }
}

// content

.firstscreenContainer {
  position: absolute;
  bottom: 0;
  background-color: $accent;
  margin: 0 10px;
  height: 55vh;
  padding: 24px 16px;
  border-radius: 24px;

  @media (max-height: 414px) {
    height: 100vh;
  }

  @media (min-width: 475px) and (max-width: 992px) {
    width: 70vw;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 992px) and (max-width: 1370px) {
    height: 55vw;

    position: static;
    width: 40vw;
    border-radius: 24px;

    display: flex;
    align-items: center;
    padding: 0 2%;
    margin: 0;
    transform: none;
  }

  @media screen and (min-width: 1370px) {
    position: static;
    width: 40vw;
    height: 45vw;
    border-radius: 24px;

    display: flex;
    align-items: center;
    padding: 0 2%;
    margin: 0;
    transform: none;
  }
}

// question
.topBarContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (min-width: 992px) {
    font-size: calc(14px + 4 * (100vw / 1280));
    margin-bottom: 16px;
  }
}

.back {
  display: none;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (min-width: 992px) {
    cursor: pointer;
    display: block;
  }
}

// options

.optionsWrapper {
  /*margin-top: 16px;*/

  @media (min-width: 992px) and (max-width: 1370px) {
    margin-top: 0;
  }

  @media (min-width: 992px) and (max-height: 850px) {
    margin-top: 0px;
  }
}

.optionsContainer {
  position: absolute;
  bottom: 0;
  background-color: $accent;
  width: calc(100% - 20px);
  height: 60vh;
  border-radius: 24px;
  padding: 24px 0;
  margin: 0 10px;

  @media (max-height: 414px) {
    height: 100vh;
  }

  @media (min-width: 475px) and (max-width: 992px) {
    width: 70vw;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 992px) and (max-width: 1370px) {
    position: relative;
    width: 40vw;
    height: 55vw;
    border-radius: 24px;
    padding: 24px 0;
    margin: 0;
    transform: none;
    left: 0;
  }

  @media screen and (min-width: 1370px) {
    position: relative;
    width: 40vw;
    height: 45vw;
    border-radius: 24px;
    padding: 32px 0;
    margin: 0;
    transform: none;
    left: 0;
  }
}

.optionsContainer::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.header {
  padding: 0 12px;

  @media screen and (min-width: 992px) {
    padding: 0 5%;
  }
}

.buttonContainer {
  margin-left: 8%;
  
  @media (min-width: 992px) and (max-width: 1400px) {
    position: absolute;
    bottom: 16px;
    width: 95%;
  }

  @media screen and (min-width: 1400px) {
    position: absolute;
    bottom: 48px;
    width: 95%;
  }
}

.titleWrapper {
  @media screen and (min-width: 992px) {
    min-height: 112px;
  }
}

.optionsItemtitle {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: 992px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.optionsItemText {
  display: block;
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }
}

.buttonNotfix {
  position: static;
  margin: 20px 0;

  @media screen and (min-width: 992px) {
    margin: 0;
  }
}

.bgWhite {
  background-color: $white;
  border-radius: 24px;
}

