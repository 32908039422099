/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"); */

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Regular.eot");
  src: url("./assets/fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Lato/Lato-Regular.woff") format("woff"),
    url("./assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Bold.eot");
  src: url("./assets/fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Lato/Lato-Bold.woff") format("woff"),
    url("./assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: "Lato", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", sans-serif, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}